const ROUTE_PATHS = {
    DEFAULT: '/',
    PRIVACY_POLICY: '/privacy-policy',
    SGLP_IDOL: '/idol',
    SGLP: '/sg-home-2021-chef-idol',
    SG_HOME_CHEF_2022: '/sg-home-2022-chef-idol',
    SGJUNIOR: '/sg-junior-chef-2021',
    SGTEENS: '/teen-chef-2022',
    HEALTHEIRLP: '/build-healthier-generations',
    INDO_RAMADA: '/ramadan-bareng',
    INDO_RAMADA_2023: '/ramadan-bareng-2023',
    INDO_JUNIOR: '/indo-junior-chef',
    INDO_JUNIOR_2: '/junior-chef-season-2',
    CNY_2022: '/cny-2022',
    CNY_2023: '/lkkcny-2023',
    CNY_2023_JOINED: '/lkkcny-2023/joined',
    KID_BAKER: '/kid-baker',
    KID_BAKER_JOINED: '/kid-baker/joined',
    SUGAR_FREE: '/sugarfree',
    SUGAR_FREE_JOINED: '/sugarfree/joined',
    DOLE_2023: '/doleeastmalaysia-2023',
    DOLE_2023_JOINED: '/doleeastmalaysia-2023/joined',
    DOLE_MYS: '/dolemysnationalday2023',
    DOLE_MYS_JOINED: '/dolemysnationalday2023/joined',
    CNY_FUNDRAISING: '/cny-fundraising',
    ALICE_NERO: '/alce-nero-cookbook',
    AYAM_COOKBOOK: '/ayam-brand-cocobingo-cookbook',
    ALCE_NERO_WORKSHOP: '/alceneroworkshop',
    ALCE_NERO_WORKSHOP_JOINED: '/alceneroworkshop/joined',
    FAMILY_DAY_2023: '/FamilyDay2023',
    FAMILY_DAY_2023_JOINED: '/FamilyDay2023/joined',
    DEL_MONTE_CNY_CONTEST: '/delmontecnycontest',
    DEL_MONTE_CNY_CONTEST_JOINED: '/delmontecnycontest/joined',
    DEL_MONTE_SCHOOL_BOOTCAMP: '/delmonteschoolholidaybootcamp',
    DEL_MONTE_SCHOOL_BOOTCAMP_JOINED: '/delmonteschoolholidaybootcamp/joined',
    BACK_TO_SCHOOL: '/tangkapbelcube',
    BACK_TO_SCHOOL_JOINED: '/tangkapbelcube/joined',
    DOLE_I_CAN_DO_IT: '/icandoleit',
    FESTIVE_COOKBOOK: '/yoripefestivecookbook2023',
    CNY_FESTIVE_COOKBOOK: '/yoripecnyfestivecookbook',
    RAMADAN_MEAL_PLAN: '/yoriperamadanmealplan2024',
    BELCUBE: '/belcube',
    KRIMKAFE: '/krimkafe',
    DOLE_FRUIT_POPS: '/dolesgfruitpopcraft',

    LOGIN: '/login',
    SIGNUP: '/signup',
    SEARCH: '/search',
    SEARCH_FILTER: '/search/:type',
    FORGOT_PASSWORD: '/forgot-password',
    STORIES: '/stories',
    RECIPES: '/recipes',
    CHALLENGES: '/challenges',
    CHALLENGES_CATEGORY: '/challenges/:category',
    STORY_PAGE: '/review/:id',
    CHALLENGE: '/challenge/:hashtag',
    RECIPE: '/recipe/:recipeSlug',
    PROFILE: '/profile/:id',
    TOP_COLLECTIONS: '/topcollections',
    COLLECTION: '/topcollections/:id',
    LIVESTREAMS: '/livestreams',
    LIVESTREAM_AUDIENCE: '/livestream/:id',
    EVENTS: '/events',
    EVENT: '/events/:id',
    GROUPS: '/groups',
    GROUP: '/groups/:id'
};

export default ROUTE_PATHS;
